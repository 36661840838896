export default {
  namespaced: true,

  state: () => ({
    data: [],
  }),

  mutations: {
    SET_GOODS(state, goods) {
      state.data = goods
    },
  },

  actions: {
    setGoodsData({ commit }, goods) {
      commit('SET_GOODS', goods)
    },
  },
}
