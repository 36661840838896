export default {
  namespaced: true,

  state: () => ({
    data: [],
  }),

  mutations: {
    SET_SUPPLIERS(state, suppliers) {
      state.data = suppliers
    },
  },

  actions: {
    setSuppliersData({ commit }, suppliers) {
      commit('SET_SUPPLIERS', suppliers)
    },
  },
}
