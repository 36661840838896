import jsCookie from 'js-cookie'

export default {
  namespaced: true,

  state: () => ({
    user: null,
    token: jsCookie.get('token') || null,
    users: [],
  }),

  getters: {
    isAuth(state) {
      return !!state.token
    },

    couriers(state) {
      return state.users.filter(courier => courier.role_id === 3)
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      jsCookie.set('token', token)
    },

    SET_USER(state, user) {
      state.user = user
    },

    DELETE_TOKEN(state) {
      state.token = null
      jsCookie.remove('token')
    },

    DELETE_USER(state) {
      state.user = null
    },

    SET_USERS(state, users) {
      state.users = users
    },
  },

  actions: {
    setUserData({ commit }, payload) {
      commit('SET_TOKEN', payload.access_token)
      commit('SET_USER', payload.name)
    },

    clearUserData({ commit }) {
      commit('DELETE_TOKEN')
      commit('DELETE_USER')
    },

    setUsersData({ commit }, users) {
      commit('SET_USERS', users)
    },
  },
}
