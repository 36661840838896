import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

import en from 'vuetify/es5/locale/en'
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify)

const locale = process.env.VUE_APP_I18N_LOCALE || 'ru'

const theme = {
  primary: '#4CAF50',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
}

export default new Vuetify({
  lang: {
    locales: { en, ru },
    current: locale,
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
