import Vue from 'vue'
import Vuex from 'vuex'
import UserModule from './modules/UserModule'
import ClientsModule from './modules/ClientsModule'
import GoodsModule from './modules/GoodsModule'
import OrdersModule from './modules/OrdersModule'
import ProductsModule from './modules/ProductsModule'
import ReferencesModule from './modules/ReferencesModule'
import SuppliersModule from './modules/SuppliersModule'
import ReviewsModule from './modules/ReviewsModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(255, 255,255, 1), rgba(255, 255, 255, 1)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
  },
  modules: {
    user: UserModule,
    clients: ClientsModule,
    references: ReferencesModule,
    goods: GoodsModule,
    orders: OrdersModule,
    products: ProductsModule,
    suppliers: SuppliersModule,
    reviews: ReviewsModule,
  },
})
