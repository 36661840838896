import ReferencesService from '@/app/Services/ReferencesService'

export default {
  namespaced: true,

  state: () => ({
    cities: [],
    orderStatuses: [],
    paymentTypes: [],
    productCategories: [],
    points: [],
    deliveryMethods: [],
    itemCategories: [],
    roles: [],
  }),

  mutations: {
    SET_REFERENCE_DATA(state, { stateName, value }) {
      state[stateName] = value
    },
  },

  actions: {
    async commitAllReferences() {
      // await this.dispatch('references/commitCities')
      // await this.dispatch('references/commitOrderStatuses')
      // await this.dispatch('references/commitPaymentTypes')
      // await this.dispatch('references/commitProductCategories')
      // await this.dispatch('references/commitPoints')
      // await this.dispatch('references/commitDeliveryMethods')
      // await this.dispatch('references/commitItemCategories')
      // await this.dispatch('references/commitRoles')
    },

    async commitCities({ commit }) {
      const response = await ReferencesService.getReferenceByUrl('cities')

      if (response.status) {
        commit('SET_REFERENCE_DATA', { stateName: 'cities', value: response.data })
      }
    },

    async commitOrderStatuses({ commit }) {
      const response = await ReferencesService.getReferenceByUrl('order-statuses')

      if (response.status) {
        commit('SET_REFERENCE_DATA', { stateName: 'orderStatuses', value: response.data })
      }
    },

    async commitPaymentTypes({ commit }) {
      const response = await ReferencesService.getReferenceByUrl('payment-types')

      if (response.status) {
        commit('SET_REFERENCE_DATA', { stateName: 'paymentTypes', value: response.data })
      }
    },

    async commitProductCategories({ commit }) {
      const response = await ReferencesService.getReferenceByUrl('product-categories')

      if (response.status) {
        commit('SET_REFERENCE_DATA', { stateName: 'productCategories', value: response.data })
      }
    },

    async commitPoints({ commit }) {
      const response = await ReferencesService.getReferenceByUrl('points')

      if (response.status) {
        commit('SET_REFERENCE_DATA', { stateName: 'points', value: response.data })
      }
    },

    async commitDeliveryMethods({ commit }) {
      const response = await ReferencesService.getReferenceByUrl('delivery-methods')

      if (response.status) {
        commit('SET_REFERENCE_DATA', { stateName: 'deliveryMethods', value: response.data })
      }
    },

    async commitItemCategories({ commit }) {
      const response = await ReferencesService.getReferenceByUrl('item-categories')

      if (response.status) {
        commit('SET_REFERENCE_DATA', { stateName: 'itemCategories', value: response.data })
      }
    },

    async commitRoles({ commit }) {
      const response = await ReferencesService.getReferenceByUrl('roles')

      if (response.status) {
        commit('SET_REFERENCE_DATA', { stateName: 'roles', value: response.data })
      }
    },
  },
}
