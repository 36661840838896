<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  computed: {
    layout() {
      const layout = this.$route.meta.layout || 'DashboardLayout'

      return () => import(`@/layouts/${layout}.vue`)
    },

    ...mapGetters({
      isAuth: 'user/isAuth',
    }),
  },

  async created() {
    if (this.isAuth) {
      await this.$store.dispatch('references/commitAllReferences')
    }
  },
}
</script>
