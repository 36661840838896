import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

// PLUGINS
import './plugins/base'
import './plugins/chartist'
import './plugins/mask'
import './plugins/vee-validate'
import './plugins/moment'
import vuetify from './plugins/vuetify'

// STYLE
import './assets/css/style.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
