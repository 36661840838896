export default {
  namespaced: true,

  state: () => ({
    data: [],
  }),

  mutations: {
    SET_PRODUCTS(state, products) {
      state.data = products
    },
  },

  actions: {
    setProductsData({ commit }, products) {
      commit('SET_PRODUCTS', products)
    },
  },
}
