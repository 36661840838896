export default {
  namespaced: true,

  state: () => ({
    data: [],
  }),

  mutations: {
    SET_ORDERS(state, orders) {
      state.data = orders
    },
  },

  actions: {
    setOrdersData({ commit }, orders) {
      commit('SET_ORDERS', orders)
    },
  },
}
