import { ApiInstance, AuthApiInstance } from '@/app/api/instance'

//BASE API
const baseApi = {
  async get(url, params = null) {
    try {
      const responseData = await ApiInstance.get(url, { params })

      return { status: true, data: responseData }
    } catch (error) {
      return { status: false, error }
    }
  },

  async post(url, data = null) {
    try {
      const responseData = await ApiInstance.post(url, data)

      return { status: true, data: responseData }
    } catch (error) {
      return { status: false, error }
    }
  },

  async put(url, data = null) {
    try {
      const responseData = await ApiInstance.put(url, data)

      return { status: true, data: responseData }
    } catch (error) {
      return { status: false, error }
    }
  },

  async delete(url, params = null) {
    try {
      const responseData = await ApiInstance.delete(url, { params })

      return { status: true, data: responseData }
    } catch (error) {
      return { status: false, error }
    }
  },
}

//AUTH API
const authApi = {
  async get(url, params = null) {
    try {
      const responseData = await AuthApiInstance.get(url, { params })

      return { status: true, data: responseData }
    } catch (error) {
      return { status: false, error }
    }
  },

  async post(url, data = null) {
    try {
      const responseData = await AuthApiInstance.post(url, data)

      return { status: true, data: responseData }
    } catch (error) {
      return { status: false, error }
    }
  },

  async put(url, data = null) {
    try {
      const responseData = await AuthApiInstance.put(url, data)

      return { status: true, data: responseData }
    } catch (error) {
      return { status: false, error }
    }
  },

  async delete(url, params = null) {
    try {
      const responseData = await AuthApiInstance.delete(url, { params })

      return { status: true, data: responseData }
    } catch (error) {
      return { status: false, error }
    }
  },
}

export { authApi }
export default baseApi
