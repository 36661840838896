export default {
  namespaced: true,

  state: () => ({
    data: [],
  }),

  mutations: {
    SET_CLIENTS(state, clients) {
      state.data = clients
    },
  },

  actions: {
    setClientsData({ commit }, clients) {
      commit('SET_CLIENTS', clients)
    },
  },
}
