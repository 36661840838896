export default {
  namespaced: true,

  state: () => ({
    data: [],
  }),

  mutations: {
    SET_REVIEWS(state, reviews) {
      state.data = reviews
    },
  },

  actions: {
    setReviewsData({ commit }, reviews) {
      commit('SET_REVIEWS', reviews)
    },
  },
}
